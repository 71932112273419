@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:300,400&display=swap");

* {
  outline: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: normal !important;
  overflow: scroll;
}

.pac-container {
  z-index: 9999 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiButton-root {
  text-transform: capitalize !important;
  font-weight: 700 !important;
}

html,
body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  word-break: normal !important;
}

p {
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
  overflow: scroll;
}

@media screen and (max-width: 640px) {
  #root {
    flex-direction: column;
  }
}

.nextui-input-helper-text-container {
  position: static !important;
}

.react-tags__combobox-input {
  width: 100% !important;
  // height: 45px;
  // background: #f1f3f5;
  // border-radius: 10px;
  // border: none;
  // padding-left: 15px;
}

.react-tags {
  position: relative;
  padding: 0.2rem 0 0 0rem;
  // border: 2px solid #afb8c1;
  border-radius: 0.75rem;
  background: #F1F3F5;
  /* shared font styles */
  font-size: 14px;
  line-height: 1.2;
  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-active {
  border-color: #4f46e5;
}

.react-tags.is-disabled {
  opacity: 0.75;
  background-color: #eaeef2;
  /* Prevent any clicking on the component */
  pointer-events: none;
  cursor: not-allowed;
}

.react-tags.is-invalid {
  border-color: #fd5956;
  box-shadow: 0 0 0 2px rgba(253, 86, 83, 0.25);
}

.react-tags__label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.react-tags__list {
  /* Do not use display: contents, it's too buggy */
  display: inline;
  padding: 0;
  margin-left: 0;
}

.react-tags__list-item {
  display: inline;
  list-style: none;
}

.react-tags__tag {
  margin: 0 0.25rem 0.25rem 0;
  padding: 0.375rem 0.5rem;
  border: 0;
  border-radius: 3px;
  background: #eaeef2;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__tag:hover {
  color: #ffffff;
  background-color: #4f46e5;
}

.react-tags__tag::after {
  content: '';
  display: inline-block;
  width: 0.65rem;
  height: 0.65rem;
  clip-path: polygon(10% 0, 0 10%, 40% 50%, 0 90%, 10% 100%, 50% 60%, 90% 100%, 100% 90%, 60% 50%, 100% 10%, 90% 0, 50% 40%);
  margin-left: 0.5rem;
  font-size: 0.875rem;
  background-color: #7c7d86;
}

.react-tags__tag:hover::after {
  background-color: #ffffff;
}

.react-tags__combobox {
  display: inline-block;
  /* match tag layout */
  padding: 0.375rem 0.25rem;
  margin-bottom: 0.25rem;
  /* prevents autoresize overflowing the container */
  max-width: 100%;
}

.react-tags__combobox-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__combobox-input::placeholder {
  color: #7c7d86;
  opacity: 1;
}

.react-tags__listbox {
  position: absolute;
  z-index: 1;
  top: calc(100% + 5px);
  /* Negate the border width on the container */
  left: -2px;
  right: -2px;
  max-height: 12.5rem;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #afb8c1;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -4px, rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
}

.react-tags__listbox-option {
  padding: 0.375rem 0.5rem;
}

.react-tags__listbox-option:hover {
  cursor: pointer;
  background: #eaeef2;
}

.react-tags__listbox-option:not([aria-disabled='true']).is-active {
  background: #4f46e5;
  color: #ffffff;
}

.react-tags__listbox-option[aria-disabled='true'] {
  color: #7c7d86;
  cursor: not-allowed;
  pointer-events: none;
}

.react-tags__listbox-option[aria-selected='true']::after {
  content: '✓';
  margin-left: 0.5rem;
}

.react-tags__listbox-option[aria-selected='true']:not(.is-active)::after {
  color: #4f46e5;
}

.react-tags__listbox-option-highlight {
  background-color: #ffdd00;
}

.Typewriter {
  display: inline-block !important;
}

.typewriter-root {
  // text-decoration: underline;
  text-decoration-thickness: 0.7rem !important;
  // text-decoration-color:#07526b;
}

.typewrite-cursor {
  font-weight: 100;
}

.list {
  position: relative;
  width: 100%;
  height: 100%;
}

.list>div {
  position: absolute;
  will-change: transform, width, height, opacity;
  padding: 15px;
}

.list>div>div {
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  border-radius: 4px;
  box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
}


.react-tags.is-active {
  border-color: #3eb68a;
}

.canvas {
	/* Gradient Color */
	--gradient-color-1: #fff04b;
	--gradient-color-2: #fff497;
	--gradient-color-3: #ffea00;
	--gradient-color-4: #ffe70c;
	height: 100%;
	width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.react-tags__listbox-option:not([aria-disabled='true']).is-active {
   background: #008373;
}

.react-tags__tag:hover {
  background: #008373;
}

.react-tags__listbox {
  z-index: 9999;
}
.react-tags__listbox-option[aria-selected='true']:not(.is-active)::after {
  color:#008373;
}

.nextui-user-info {
  white-space: pre-wrap !important;
}
.carousel > div{
  display: flex;
}

$primary-color: #007AFF;
$secondary-color: #e5e5ea;
$white-color: white;
$black-color: black;

$border-radius: 18px;
$tail-width: 25px;
$tail-height: 15px;


.chat-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
}

// Chat bubble styling
.chat-bubble {
  padding: 12px 18px;
  max-width: 100%;
  border-radius: $border-radius;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  word-wrap: break-word;
  
  // Nesting for sender and receiver
  &.sender {
    background-color: $secondary-color;
    color: $black-color;
    align-self: flex-start;

    // Tail for the sender
    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: -10px;
      border-left: 0;
      border-right: $tail-width solid $secondary-color;
      border-top: $tail-height solid transparent;
      border-bottom: $tail-height solid transparent;
    }
  }

  &.receiver {
    background-color: $secondary-color;
    color: $white-color;
    align-self: flex-end;
    border-bottom-right-radius: 0;

    // Tail for the receiver
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -10px;
      border-left: $tail-width solid $secondary-color;
      border-right: 0;
      border-top: $tail-height solid transparent;
      border-bottom: $tail-height solid transparent;
    }
  }
}

.talk-bubble {
	margin-top: 5%;
  margin-bottom: 5%;
  display: inline-block;
  position: relative;
	height: auto;
	background-color: var(--message-color);
  padding: 1rem;
}
.border{
  border: 8px solid #666;
}
.round{
  border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;

}

.tri-right.right-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  right: -16px;
	left: auto;
  top: 0px;
	bottom: auto;
	border: 22px solid;
	border-color: var(--message-border-color, black) transparent transparent transparent;
}


/* talk bubble contents */
.talktext{
  padding: 1em;
	text-align: left;
  line-height: 1.5em;
}
.talktext p{
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.styles-module_item-tracker__3bypy {
  align-items: center;
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width:1024px) { 
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
